@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.maincontainer {
  width: 100%;
  display: flex;
}
.card2Sec {
  padding-top: 20px;
  width: 100%;
  height: 200px;
  display: flex;
  background-color: white;
  align-items: center;
  position: relative;
  justify-content: space-evenly;
  margin-left: 15px;
}
.glasses1 {
  min-width: 100px;
  min-height: 100px;
  width: 160px;
  height: 160px;
  border-radius: 10px;
}
.details4 {
  width: 100%;
  text-align: justify;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: clamp(10px, 1.2vw, 11px);
}
.headDetails {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  justify-content: space-evenly;
  gap: 10px;
}
.detailsSec {
  padding: 10px 25px;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
  text-align: justify;
}
.headingSec {
  font-size: clamp(15px, 1.8vw, 20px);
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}
.summary {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: medium;
}
.priceSec {
  text-align: center;
  font-size: clamp(13px, 1.3vw, 17px);
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.display {
  display: flex;
  flex-direction: row;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
.disButtons {
  gap: 10px;
  display: flex;
  align-items: center;
  border: 2px solid #e8e8eb;
  height: 35px;
  width: 100px;
}
.countBtn {
  width: 35px;
  height: 20px;
  border: none;
  font-weight: 400;
  background-color: #fff;
  font-size: clamp(15px, 1.4vw, 20px);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.closebtn {
  width: 7rem;
  height: 80%;
  border: none;
  color: rgb(250, 141, 141);
  border-radius: 5px;
  transition: ease 0.3s;
  cursor: pointer;
  background-color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.closebtn:hover {
  color: rgb(211, 21, 21);
  letter-spacing: 1px;
}
.rateRemove {
  display: flex;
  height: 100%;
  gap: 5%;
  width: 100px;
  justify-content: flex-end;
}

@media screen and (max-width: 600px) {
  .card2Sec {
    margin-left: 0;
    column-gap: 10px;
    padding-top: 0px;
    height: 160px;
    position: relative;
  }
  .glasses1 {
    width: 80px;
    height: 80px;
    min-width: 60px;
    min-height: 60px;
  }
  .headingSec {
    font-size: 12px;
  }
  .details4 {
    font-size: 9px;
  }
  .display {
    height: 20px;
  }
  .rateRemove{
    flex-wrap: wrap;
    justify-content: end;
  }
  .closebtn{
    width: auto;
    position: absolute;
    padding-top: 50px;
    height: 20px;
    justify-content: end;
    font-size: 0.6rem;
    right: 0px;
    top: -15px;
  }
  .quantity{
    font-size: 0.7rem;
  }
  .disButtons{
    width: 80px;
    height: 24px;
  }
  .headDetails{
    justify-content: center;
  }
  .priceSec{
    width: 75px;
    font-size: 0.7rem;
  }
}
