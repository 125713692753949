.addressContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
}
.formContainer {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.secContainer {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.addressMain {
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
form {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: flex-start;
  height: 100%;
  margin-top: 30px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: clamp(11px, 1.3vw, 14px);
}
form select,
option {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: clamp(11px, 1.3vw, 14px);
}
.header3 {
  margin-top: 6rem;
  width: 100%;
  padding-left: 10%;
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: clamp(12px, 1.5vw, 17px);
}
input {
  height: 30px;
  background-color: #f3f3f3;
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 16px;
}
textarea {
  height: 120px;
  background-color: #f3f3f3;
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 16px;
}
select,
option {
  background-color: #f3f3f3;
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 16px;
}
.subBtn {
  margin-top: 3rem;
  background-color: #3aa39f;
  height: 40px;
  border: none;
  outline: none;
  border-radius: 10px;
  box-shadow: -4px -4px 8px rgba(255, 255, 255, 0.5),
    4px 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  cursor: pointer;
  color: white;
  transition: all 0.3s ease;
}
.subBtn:hover {
  box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.5),
    2px 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #007c78;
}
.billButton {
  margin-top: 3rem;
  background-color: #3aa39f;
  height: 40px;
  border: none;
  outline: none;
  border-radius: 10px;
  box-shadow: -4px -4px 8px rgba(255, 255, 255, 0.5),
    4px 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  cursor: pointer;
  color: white;
  transition: all 0.3s ease;
  width: 40%;
}
.noAddress{
    font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: clamp(11px, 1.3vw, 14px);
}
@media screen and (max-width: 600px) {
  .addressContainer {
    flex-direction: column-reverse;
    position: relative;
  }
  .formContainer {
    width: 100%;
  }
  .secContainer {
    width: 100%;
    padding-top: 100px;
  }
  .addressMain{
    padding-top: 20px;
  }
  form {
    width: 80%;
    margin-bottom: 20px;
  }
  .header3 {
    margin-top: 5rem;
    position: absolute;
    left: 0px;
    top: 0px;
  }
  .billButton{
    width: 80%;
    min-height: 38px;
  }
}
