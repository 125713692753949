@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap");

.maincontainer{
    gap: 3rem;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 60px;
    padding-bottom: 30px;
    position: relative;
}
.spinner {
    position: absolute;
    top: 40%;
    width: 56px;
    height: 56px;
    display: grid;
    border: 4.5px solid #0000;
    border-radius: 50%;
    border-color: #dbdcef #0000;
    animation: spinner-e04l1k 1s infinite linear;
  }
  
  .spinner::before,
  .spinner::after {
    content: "";
    grid-area: 1/1;
    margin: 2.2px;
    border: inherit;
    border-radius: 50%;
  }
  
  .spinner::before {
    border-color: #474bff #0000;
    animation: inherit;
    animation-duration: 0.5s;
    animation-direction: reverse;
  }
  
  .spinner::after {
    margin: 8.9px;
  }
  #carouselExampleIndicators{
    width: 100%;
  }
  .carousel-inner{
    /* background-image: url(https://cdn.shopify.com/s/files/1/0070/7032/files/how_20to_20start_20a_20clothing_20brand.png?v=1693935729);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
    width: 100%;
    height: 300px;
    color: white;
  }
  .carasoulContainer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    font-weight: 600;
    font-family: "League Spartan", sans-serif;
  }
  .carousel-item img{
    width: 11vw;
    position: absolute;
    left: 0px;
    top: 0px;
  }
  .carousel-item{
    height: 100%;
  }
  @keyframes spinner-e04l1k {
    100% {
      transform: rotate(1turn);
    }
  }
  @media screen and (max-width: 500px) {
    .carousel-item img{
      width: 4rem;
    }
    .carousel-inner{
      height: 150px;
    }
    .carasoulContainer h6{
      font-size: 1rem !important;
    }
  }