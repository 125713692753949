@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap");

.fullBody {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: hidden;
  min-height: 100vh;
}
.leftBody {
  min-width: 330px;
  width: 50%;
  padding-top: 60px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-family: "League Spartan", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
.rightBody {
  padding-right: 30px;
  min-width: 330px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
  overflow: hidden;
}
.rightBody h1 {
  color: black;
  font-size: clamp(25px, 3.5vw, 80px);
}
.rightBody p {
  margin-top: 20px;
  font-size: clamp(14px, 1.4vw, 30px);
}
.rightBody h3 {
  font-size: clamp(10px, 1.3vw, 25px);
}
.leftBody img {
  max-width: 850px;
}
.greenH1 {
  color: #088178 !important;
}
.mainpgBtn {
  position: relative;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  padding-block: 0.5rem;
  padding-inline: 1.25rem;
  background-color: #4951d7;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #ffff;
  gap: 10px;
  font-weight: bold;
  border: 3px solid #ffffff4d;
  outline: none;
  overflow: hidden;
  font-size: 15px;
  margin-top: 50px;
  margin-left: 20px;
}

.mainpgBtn:hover {
  transform: scale(1.05);
  border-color: #fff9;
}
.mainpgbtn:hover .icon {
  transform: translate(4px);
}
.icon {
  width: 24px;
  height: 24px;
  transition: all 0.3s ease-in-out;
}
.mainpgbtn:hover::before {
  animation: shine 1.5s ease-out infinite;
}

.mainpgbtn::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  opacity: 0.6;
}
.secondContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.secondHeader {
  padding: 50px 20px;
}
.itemContainer {
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: flex-start;
  grid-template-columns: repeat(auto-fill, minmax(250px, 23%));
  grid-column-gap: 8%;
  grid-row-gap: 40px;
}
.item {
  border: 0.1px solid #0881793e;
  height: 100%;
  padding: 15px;
  border-radius: 20px;
  box-shadow: 20px 20px 30px rgb(0, 0, 0, 0.2);
}
.item img {
  width: 100%;
  border-radius: 15px;
}
.brandName {
  font-size: clamp(10px, 1.2vw, 15px);
}
.modelName {
  font-size: clamp(14px, 1.4vw, 19px);
  font-weight: 600;
}
.rateAndCart {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.stars {
  height: 20px;
}
.stars svg {
  height: 90%;
}
.starAndPrice {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50px;
}
.cartButton {
  transition: transform 0.3s ease;
  border-radius: 50%;
  height: 40px;
  width: 40px;
}
.cartButton:hover {
  transform: rotate(-20deg);
}
.thirdContainer {
  width: 100%;
  padding: 50px 0px;
}
.adBox {
  background: url(/public/assets/b2.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 290px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 10px;
}
.adBox span {
  color: red;
}
.forthContainer {
  width: 100%;
  padding-top: 50px;
  display: flex;
  gap: 2%;
}
.firstAd {
  width: 60%;
  height: 330px;
  background-image: url(/public/assets/b10.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 40px;
}
.secondAd{
  width: 40%;
  height: 330px;
  background-image: url(/public/assets/b7.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 2%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 20px;
}
.fifthContainer{
  width: 100%;
  padding-top: 50px;
}
.newsletter{
  width: 100%;
  background-color: #041e42;
  height: 200px;
  background-image: url(/public/assets/b14.png);
  background-repeat: repeat;
  background-position: center;
  color: white;
  display: flex;
}
.aboutSection{
  width: 100%;
  padding-top: 100px;
  display: flex;
}
.aboutSection h6{
  color: grey;
  font-weight: 300;
}
.aboutSection h6:hover{
  color: black;
}
.aboutRight, .aboutLeft{
  width: 50%;
  display: flex;
}
.section1,.section2,.section3,.section4{
  padding-left: 40px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
}
.section1{
  padding-top: 0px !important;
  padding-left: 40px !important;
  padding-right: 20px !important;
}
.section4{
  padding-left: 0 !important;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  row-gap: 15px;
  padding-right: 30px;
}
.section4 h6,.section4 h5{
  padding-left: 30px;
}
.section2,.section3{
  width: 30%;
  display: flex;
  align-items: stretch;
}
.section1,.section4{
  width: 70%;
}
.section1 h6, .section4 h6{
  color: black;
}
.flipLogo{
  width: 100%;
  background-image: url(/public/assets/flipLogo.png);
  height: 100px;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 30px;
  background-position: left;
  cursor: pointer;
}
.appleGoogle{
  width: 100%;
  height: 50px;
  display: flex;
  padding-left: 30px;
  cursor: pointer;
}
.appleStore{
  background-image: url(/public/assets/appStore.png);
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 50%;
}
.playStore{
  background-image: url(/public/assets/playStore.png);
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 50%;
}
.gateway{
  width: 100%;
  height: 40px;
  background-image: url(/public/assets/creditCard.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.copyRight{
  width: 100%;
  padding: 50px 20px;
}
@keyframes shine {
  0% {
    left: -100px;
  }
  60% {
    left: 100%;
  }
  to {
    left: 100%;
  }
}
@media screen and (max-width: 900px) {
  .aboutLeft{
    flex-wrap: wrap;
  }
  .aboutRight{
    flex-wrap: wrap-reverse;
    row-gap: 20px;
  }
  .section1,.section2,.section3,.section4{
    width: 100%;
  }
  .aboutSection h5{
    font-size: 15px;
  }
  .aboutSection h6{
    font-size: 13px;
  }
  .copyRight{
    font-size: 14px;
  }
}
@media screen and (max-width: 576px) {
  .leftBody {
    width: 100%;
  }
  .leftBody img {
    width: 550px;
    min-width: 450px;
  }
  .rightBody {
    padding: 0px 20px;
    font-size: 15px;
    min-width: 250px;
    width: 500px;
  }
  .forthContainer{
    flex-wrap: wrap;
    padding-top: 0px;
  }
  .secondAd,.firstAd{
    width: 100%;
    margin: 0;
  }
  .newsletter{
    flex-wrap: wrap;
  }
  .newsMail, .newsText{
    width: 100% !important;
    padding: 10px;
  }
  .aboutSection{
    flex-wrap: wrap;
    padding-top: 20px;
  }
  .aboutLeft,.aboutRight{
    width: 100%;
  }
  .flipLogo{
    background-position: left;
  }
  .aboutSection h6{
    font-size: 12px;
  }
  .aboutSection h5{
    font-size: 14px;
  }
  .copyRight{
    font-size: 12px;
  }
  .appleGoogle{
    justify-content: space-around;
  }
  .appleStore,.playStore{
    width: 40%;
  }
  .mainpgBtn{
    margin-bottom: 30px;
    margin-top: 20px;
  }
  .secondHeader{
    padding-top: 20px;
  }
}