@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.maincartContainer {
  width: 100%;
  padding-top: 80px;
  min-height: 100vh;
  display: flex;
  align-content: flex-start;
  gap: 10%;
  padding-bottom: 30px;
  justify-content: flex-start;
  margin-left: 5%;
}
.right {
  width: 40%;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  right: 60px;
}
.left {
  width: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.emptyCart{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: clamp(12px, 1.5vw, 17px);
}
.coupen,.shipping,.discount,.totalprice,.totalamountShow,.deliverydate{
  display: flex;
  justify-content: space-between;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: clamp(12px, 1.5vw, 17px);
}
.amountDisplay {
  background-color: white;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  height: 600px;
  width: 70%;
  margin-top: 30px;
  border: 3px solid #e8e8eb;
  flex-direction: column;
}
.totalamountShow {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.amount {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: clamp(13px, 1.3vw, 17px);
}
.buttons {
  width: 100%;
  row-gap: 7px;
  display: flex;
  flex-direction: column;
}
.free{
  color: red;
}
.buynowBtn,
.addAdress {
  width: 100%;
  height: 35px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: clamp(12px, 1.2vw, 15px);
  display: flex;
  justify-content: center;
  background-color: #3aa39f;
  border: none;
  border-radius: 5px;
  align-items: center;
  cursor: pointer;
  color: white;
}
.buynowBtn:hover,
.addAdress:hover {
 background-color: #04837e;
}
.cartCount{
  color: black;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 1.1rem;
}
.countGrey{
  margin-left: 10px;
  color: #a2a3b1;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 0.9rem;
}
.dateDiv{
  text-align: end;
}
@media screen and (max-width: 600px) {
  .maincartContainer{
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 0;
    gap: 0%;
  }
  .right{
    position: static;
    width: 100%;
    justify-content: center;
  }
  .left{
    width: 90%;
    row-gap: 0;
  }
  .amountDisplay{
    width: 90%;
    padding: 10px;
    height: 400px;
  }
  .emptyCart{
    padding-top: 30px;
  }
}
