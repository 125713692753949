@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.billContainer {
  margin-top: 80px;
  padding: 30px 0;
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  display: flex;
  justify-content: center;
}
.invoice {
  background-color: #fff;
  width: 100%;
  margin: 0 auto;
  border: 2px solid #9e9e9e;
  overflow: hidden;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  flex-direction: column;
}
.mainContent {
  width: 100%;
  height: 90px;
  background-color: #2196f378;
  padding-left: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.mainContent img {
  width: 140%;
}
.secondaryContent {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 30px;
}
.secondaryContent p {
  font-size: clamp(9px, 1.3vw, 15px);
}
.shop-info {
  max-width: 50%;
  line-height: 15px;
  font-size: clamp(10px, 1.4vw, 18px);
}
.shop-logo {
  max-width: 200px;
  height: auto;
}
.customor-details {
  max-width: 50%;
  text-align: justify;
  line-height: 12px;
}
.customor-details h4 {
  font-size: clamp(10px, 1.4vw, 18px);
}
.itemized-list {
  margin-bottom: 20px;
  width: 100%;
  padding: 0px 50px;
}
.itemized-list th {
  font-size: clamp(9px, 1.3vw, 17px);
}
.itemized-list td {
  font-size: clamp(8px, 1.3vw, 16px);
}
.itemized-list th,
.itemized-list td {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}
.total {
  display: flex;
  margin-top: 20px;
  padding-right: 10%;
  flex-direction: column;
  align-items: flex-end;
  line-height: 5px;
  font-size: clamp(12px, 1.4vw, 18px);
}
.total span {
  font-weight: bold;
  color: #3f51b5;
}
.Thanks {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  flex-direction: column;
}
.thanksText {
  background-color:#2196f378;
  height: 70%;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: clamp(12px, 1.5vw, 19px);
}
.com {
  height: 30%;
  font-size: clamp(8px, 1.1vw, 13px);
  margin-top: 20px;
}
tbody {
  text-align: center;
}
.notFount {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.notFount img{
  height: 33rem;
}
@media screen and (max-width: 800px) {
  .invoice {
    max-width: 90%;
  }
}
@media screen and (max-width: 500px) {
  .itemized-list {
    padding: 0px 10px;
  }
  .shop-info {
    line-height: 13px;
  }
  .secondaryContent p {
    margin: 0px;
    padding-top: 15px;
  }
  .invoice {
    border-radius: 10px;
  }
  .shop-info,
  .customor-details {
    line-height: 5px;
  }
  .notFount img{
   height: 17rem;
  }
  .mainContent img {
    width: 80%;
  }
  .mainContent{
    padding-left: 10px;
  }
  .BillUpperDivision{
    width: 99% !important;
  }
}
