@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap");

.card2 {
  width: 280px;
  height: 410px;
  background-color: white;
  border-radius: 20px;
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  box-shadow: 20px 20px 30px rgb(0, 0, 0, 0.2);
  padding: 15px;
  border: 0.1px solid #0881793e;
  justify-content: flex-start;
  gap: 10px;
}
.card2 img{
  width: 100%;
  border-radius: 15px;
}
/* .card2 img:hover{
  transform: scale(1.05); 
  transition: transform 0.3s ease-in-out;
} */
.heading {
  width: 100%;
  font-size: clamp(14px, 1.4vw, 19px);
  font-weight: 600;
  font-family: "League Spartan", sans-serif;
}
.price {
  font-family: "League Spartan", sans-serif;
  font-weight: 600;
  font-style: normal;
  padding-top: 15px;
  width: 100%;
}
.rateAndButton{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.starAndRate{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50px;
}
.btn1 {
  transition: transform 0.3s ease;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn1:hover{
  transform: rotate(-20deg);
}
@media screen and (max-width : 500px) {
  .card2{
    width: 250px;
    height: 380px;
  }
}

