@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
a {
  text-decoration: none;
}
.navbar {
  width: 100%;
  height: 60px;
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  background-color: #ffff;
  color: black;
  position: fixed;
  top: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
  z-index: 10;
  /* overflow: visible; */
  box-shadow: 0px -5px 8px grey;
}
.navbar h3 {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: clamp(12px, 1.7vw, 20px);
}
.navbar button {
  text-decoration: none;
  color: black;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.switches {
  display: flex;
  gap: 5vw;
  padding-right: 5%;
  font-size: clamp(10px, 4vw, 17px);
  font-weight: 700;
  height: 100%;
  align-items: center;
}
.services{
  position: relative;
}
.cartCount_Navbar{
  width: 60%;
  height: 60%;
  position: absolute;
  background-color: #F44336;
  font-size: 70%;
  border-radius: 50%;
  right: -15px;
  top: -5px;
  color: white;
  font-weight: 400;
}
.mainname {
  padding-left: 25px;
  font-size: clamp(8px, 3vw, 15px);
  width: 21vw;
}
.mainname img {
  width: 140px;
  height: 40px;
}
.switches .active{
  border-bottom: 2px solid #ffc107;
  color: #ffc107;
}
@media screen and (max-width: 576px) {
  .switches {
    font-size: 9px;
    gap: 3px;
  }
  .navbar button {
    font-size: 10px;
  }
  .mainname{
    padding-left: 10px;
    width: 27vw;
  }
  .mainname img{
    width: 100px;
    height: 30px;
  }
  .cartCount_Navbar{
    right: -7px;
    width: 48%;
  }
}
